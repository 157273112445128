import React, { useEffect, useState } from 'react';

const About: React.FC = () => {
    const [htmlContent, setHtmlContent] = useState('');

    useEffect(() => {
        fetch('/scripts/resume.html')  // 确保路径是正确的
            .then(response => response.text())  // 获取响应文本
            .then(html => {
                setHtmlContent(html);  // 设置 HTML 内容
            })
            .catch(error => {
                console.error('Failed to load html:', error);
            });
    }, []);

    return (
        <div style={{overflowY: 'auto', maxHeight: '90vh' }}>
            {/* 个人信息部分 */}
            <div style={{marginBottom: '50px'}}>
                <h2>About Me</h2>
                <p>成长于江城，求学于帝都，目前是一名大三的本科生，一个希望自己有趣、浪漫、热情的理工男，喜欢不断尝试，擅长面对失败。</p>
                <p>一直希望做点有价值的工作，受无数热爱技术的前辈影响，怀着对个人成长、能力提升的渴望，我创建了这个网站。</p>
                <p>在这里，我希望与你一起分享我的学习、求职之路，工作经历以及对未来技术趋势的思考。</p>
            </div>

            {/* 简历部分 */}
            <div style={{marginBottom: '20px'}}>
                <h2>Resume</h2>
                {/* <p>我的简历可以在这里下载: <a href="/scripts/resume.pdf" target="_blank" rel="noopener noreferrer">简历</a></p> */}
                <p>以下是我的简历：</p>
                <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
            </div>
            
        </div>
    );
}

export default About;
