import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './index.css';
import App from './App';
import Home from './Home';
import Blog from './Blog';
import Resume from './About';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Router>
      <nav>
        <Link to="/">主页</Link>
        <Link to="/blog">博客</Link>
        <Link to="/message-board">留言板</Link>
        <Link to="/about">关于</Link>
        <a href="http://liubf.fun:6010" target="_blank" rel="noopener noreferrer" className="nav-link">推荐</a>
      </nav>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/message-board" element={<App />} />
        <Route path="/about" element={<Resume />} />
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
