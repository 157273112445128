import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
// import { marked } from 'marked';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';

const Blog: React.FC = () => {
    const [articles, setArticles] = useState<string[]>([]); 
    const [selectedArticle, setSelectedArticle] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const apiUrl = 'https://liubf.fun'; // API URL

    // 获取文章列表
    const fetchArticles = async () => {
        try {
            const response = await fetch(`${apiUrl}/blog/articles`, {
                headers: { 'X-API-KEY': 'your_api_key_here' }
            });
            if (response.ok) {
                const filenames = await response.text();
                setArticles(filenames.split('\n').filter(name => name)); // 分割字符串并过滤空字符串
            } else {
                console.error('Failed to fetch articles');
            }
        } catch (error) {
            console.error('Error fetching articles:', error);
        }
    };

    // 加载文章内容
    const loadArticle = async (articleName: string) => {
        try {
            const response = await fetch(`/articles/${articleName}`);
            const markdown = await response.text();
            // const parsedMarkdown = await marked(markdown);
            // setSelectedArticle(parsedMarkdown);
            setSelectedArticle(markdown);
            setModalIsOpen(true);
        } catch (error) {
            console.error(error);
        }
    };

    // 当组件挂载时，获取文章列表
    useEffect(() => {
        fetchArticles();
    }, []);
    

    return (
        <div style={{
            textAlign: 'center',
            color: '#fff',
            position: 'absolute',
            top: '10%',    // 顶部留空 
            left: '10%',   // 左侧留空 
            right: '10%',  // 右侧留空 
            bottom: '10%', // 底部留空 
            border: '2px solid #ccc',
            background: '#eff',
            overflow: 'auto', // 内容超出时显示滚动条
            WebkitOverflowScrolling: 'touch', // 兼容苹果设备
            borderRadius: '10px',
            outline: 'none',
            padding: '20px' // 内容与边框的间距
        }}>

            <h1 style={{ color: '#063736' }}>我的文章</h1>
            <ul style={{ listStyleType: 'none', padding: 0 }}>
                {articles.map((article, index) => (
                    <li key={index} onClick={() => loadArticle(article)} style={{ cursor: 'pointer', color: '#063736' }}> {/* 更改列表项颜色 */}
                        {article}
                    </li>
                ))}
            </ul>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                contentLabel="文章内容"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.75)' // 半透明背景
                    },
                    content: {
                        position: 'absolute',
                        top: '12%',    // 顶部留空 
                        left: '12%',   // 左侧留空 
                        right: '12%',  // 右侧留空 
                        bottom: '12%', // 底部留空 
                        border: '1px solid #ccc',
                        background: '#fff',
                        overflow: 'auto', // 内容超出时显示滚动条
                        WebkitOverflowScrolling: 'touch',
                        borderRadius: '4px',
                        outline: 'none',
                        padding: '20px' // 内容与边框的间距
                    }
                }}
            >
                {/* <div dangerouslySetInnerHTML={{ __html: selectedArticle }} style={{ height: '100%', overflowY: 'auto' }} /> */}
                <div style={{ height: '100%', overflowY: 'auto' }}>
                    <ReactMarkdown
                        children={selectedArticle}
                        remarkPlugins={[remarkMath]}
                        rehypePlugins={[rehypeRaw, rehypeKatex]}
                        components={{
                            img: ({ node, ...props }) => (
                                <img
                                    {...props}
                                    style={{ maxWidth: '60%', height: 'auto' }}
                                />
                            ),
                        }}
                    />
                </div>       
                <button onClick={() => setModalIsOpen(false)} style={{
                    padding: '3px 10px',
                    border: '1px solid #5caed4', // 浅蓝色边框
                    borderRadius: '30px', // 圆角
                    backgroundColor: '#eff', // 白色背景
                    color: '#5caed4', // 浅蓝色文字
                    fontSize: '16px', // 字体大小
                    cursor: 'pointer', // 鼠标样式
                    outline: 'none', // 去除焦点边框
                    position: 'absolute', // 绝对定位
                    bottom: '10px', // 底部定位
                    left: '50%', // 水平居中
                    transform: 'translateX(-50%)', // 水平居中调整
                    textDecoration: 'none' // 去除文本下划线
                }}>
                    关闭
                </button>
            </Modal>

        </div>
    );
};

export default Blog;
