// src/App.tsx

import React, { useState, useEffect, useRef } from 'react';
import './App.css';

interface Message {
  nickname: string;
  message: string;
  timestamp: number;
}

const App = () => {
  const [nickname, setNickname] = useState('');
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState<Message[]>([]);
  const apiUrl = 'https://liubf.fun'; // API URL

  const formatTimestamp = (timestamp: number) => {
    const date = new Date(timestamp);
    return date.toLocaleString(); // 格式化时间戳
  };

  const addMessage = () => {
    if (!message) {
      alert("请输入你的留言");
      return;
    }

    fetch(`${apiUrl}/MessageBoardService/AddMessage`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ message, nickname: nickname || 'Guest' })
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      getMessages(); // 刷新留言列表
      setMessage(''); // 清空留言输入框
    })
    .catch(error => console.error('Error:', error));
  };

  const getMessages = () => {
    fetch(`${apiUrl}/MessageBoardService/GetMessages`)
      .then(response => response.json())
      .then(data => setMessages(data.messages))
      .catch(error => console.error('Error:', error));
  };

  useEffect(() => {
    getMessages(); // 初始获取留言
  }, []);

  const starsRef = useRef<HTMLDivElement | null>(null);
  const shootingStarsRef = useRef<HTMLDivElement | null>(null);
  
  useEffect(() => {
    // 生成星星
    const stars = starsRef.current;
    const shootingStars = shootingStarsRef.current;
  
    if (stars && shootingStars) {
      // 生成星星
      for (let i = 0; i < 100; i++) {
        const star = document.createElement('div');
        star.className = 'star';
        star.style.left = `${Math.random() * 100}%`;
        star.style.top = `${Math.random() * 100}%`;
        stars.appendChild(star);
      }
  
      // 生成流星
      for (let i = 0; i < 10; i++) {
        const shootingStar = document.createElement('div');
        shootingStar.className = 'shooting-star';
        shootingStar.style.left = `${Math.random() * 100}%`;
        shootingStar.style.top = `${Math.random() * 100}%`;
        shootingStar.style.animationDuration = `${Math.random() * 2 + 1}s`;
        shootingStar.style.animationDelay = `${Math.random() * 5}s`;
        shootingStars.appendChild(shootingStar);
      }
    }
  }, []);  


  return (
    <div>
      <div className="container">
        <h1>留言板</h1>
        <p>欢迎来到我的留言板，欢迎在这里留下你来过的足迹。</p>
        <input
          type="text"
          className="nickname-input input-field"
          value={nickname}
          onChange={(e) => setNickname(e.target.value)}
          placeholder="请输入你的昵称"
        />
        <input
          type="text"
          className="message-input input-field"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="请输入你的留言"
        />
        <button className="message-submit" onClick={addMessage}>提交</button>
        <ul className="message-list">
          {messages.map((msg, index) => (
            <li key={index} className="message-item">
              <div className="message-content">{`${msg.nickname}: ${msg.message}`}</div>
              <div className="message-time">{formatTimestamp(msg.timestamp)}</div>
            </li>
          ))}
        </ul>
      </div>
      <div className="stars" ref={starsRef}> </div>
      <div className="shooting-stars" ref={shootingStarsRef}></div>
    </div>
  );
};

export default App;
