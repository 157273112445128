import { error } from 'console';
import React, { useEffect, useState } from 'react';

const Home: React.FC = () => {
    const [inputMessage, setInputMessage] = useState('');
    const [messages, setMessages] = useState<string[]>([]);
    const [currentMessage, setCurrentMessage] = useState<string>('');
    const [isWaitingForResponse, setIsWaitingForResponse] = useState(false); // 新状态变量
    let lastReceivedPosition = 0; // 用于跟踪最后接收数据的位置

    const handleSendMessage = () => {
        if (inputMessage.trim() === '') {
            return;
        }

        setIsWaitingForResponse(true); // 等待回复，禁用输入框
        const xhr = new XMLHttpRequest();
        xhr.open('POST', 'https://liubf.fun/MessageBoardService/ChatWithExternalApi', true); //'http://liubf.fun:8080/MessageBoardService/ChatWithExternalApi', true);
        xhr.setRequestHeader('Content-Type', 'application/json');

        xhr.onreadystatechange = () => {
            // if (xhr.readyState === XMLHttpRequest.LOADING) 
            {
                const currentData = xhr.responseText;
                console.log('接收消息:', currentData);
                const newData = currentData.substring(lastReceivedPosition);
                lastReceivedPosition = currentData.length; // 更新位置

                const lines = newData.split('\n');
                lines.forEach(line => {
                    let message = line;
                    if (message.startsWith('data: ')) {
                        message = message.substring(6);
                    }
                    if (message) {
                        setCurrentMessage(prevMessage => prevMessage + message);
                    }
                    
                });
            }

            if (xhr.readyState === XMLHttpRequest.DONE) {
                setIsWaitingForResponse(false);
            }
        };

        xhr.onerror = () => {
            console.error('Error sending message');
        };
        const prompt = '你现在的角色是程序员 Buffer 的网站助手！他的个人介绍是这样的‘成长于江城，求学于帝都，目前是清华大四的一名本科生，一个希望自己有趣、浪漫、热情的理工男，喜欢跌宕起伏的生活，屡战屡败，永远在路上。一直希望做点有价值的工作，受无数热爱技术的前辈影响，怀着对个人成长、能力提升的渴望，我创建了这个网站。在这里，我希望与你一起分享我的学习、求职之路，工作经历以及对未来技术趋势的思考。’作为他的网站助手，希望你能尽可能幽默风趣地帮助他解答网站访问者的问题，必要时请向读者介绍网站背景，但注意你的回复应当尽量简洁: \n';
        const payload = JSON.stringify({ messages: [{ role: 'user', content: prompt + inputMessage }] });
        xhr.send(payload);

        console.log('发送消息:', inputMessage);

        if (currentMessage) {
            setMessages(prevMessages => [...prevMessages, `Bot: ${currentMessage}`]);
            setCurrentMessage('');
        }
        setMessages(prevMessages => [...prevMessages, `你: ${inputMessage}`]);
        setInputMessage('');
    };


    return (
        <div style={{ textAlign: 'center', color: '#fff' }}>
            <h1>欢迎来到 Buffer 的网站</h1>
            <p>你可以在这里找到我的留言板、博客文章，</p>
            <p>还可以通过下方输入和我设置的 Bot 进行对话：</p>
            <div style={{ marginTop: '20px' }}>
                <div style={{ height: '300px', overflowY: 'auto', backgroundColor: '#e0f0f0', padding: '10px', color: '#000', textAlign: 'left', maxWidth: '60%', minWidth: '300px', margin: '0 auto' }}>
                    {messages.map((msg, index) => (
                        <div key={index}>{msg}</div>
                    ))}
                    <div>{currentMessage}</div>
                </div>
                <input 
                    type="text" 
                    value={inputMessage} 
                    onChange={(e) => setInputMessage(e.target.value)}
                    style={{ marginRight: '10px', padding: '10px' }}
                    disabled={isWaitingForResponse} // 根据状态禁用或启用输入框
                    placeholder={isWaitingForResponse ? "请等待回复" : "输入消息"}
                />
                <button onClick={handleSendMessage} disabled={isWaitingForResponse} // 根据状态禁用或启用输入框
                >发送</button>
            </div>
        </div>
    );
}

export default Home;
